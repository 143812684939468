<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 px-0">
          <dx-data-grid
              id="invoiceGrid"
              ref="invoiceGridRef"
              :data-source="dataSource"
              :height="setHeight"
              :cache-enabled="false"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :hover-state-enabled="true"
              :row-alternation-enabled="false"
              @initialized="onInitialized"
              @content-ready="onContentReady"
              @toolbar-preparing="onToolbarPreparing($event)"
          >
            <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-search-panel :visible="false" :width="200" placeholder="Search..." />
            <dx-filter-row :visible="false" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <!--endregion ::DataGird base components -->
            <!--region    ::DataGird columns -->
            <dx-column data-field="invoiceNo" alignment="left" />
            <dx-column data-field="creationTime" data-type="date" />
            <dx-column v-if="hasPermission" data-field="accountNo" caption="Acc #" alignment="left" :allow-sorting="false" cell-template="accountCell" />
            <dx-column v-if="hasPermission" data-field="suiteNo" caption="Suite #" alignment="left" :allow-sorting="false" cell-template="accountCell" />
            <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
            <dx-column data-field="companyName" caption="Company" :allow-sorting="false" />
            <dx-column data-field="storeName" caption="Store" :allow-sorting="false" />
            <dx-column data-field="invoiceAmount" caption="Amount" cell-template="invoiceAmountTemplate" />
            <template #invoiceAmountTemplate="{ data }">
              {{ getCurrencyFormat(data.value) }}
            </template>
            <dx-column data-field="totalDue" caption="Due" cell-template="totalDueCellTemplate" />
            <template #totalDueCellTemplate="{ data }">
              {{ getCurrencyFormat(data.value) }}
            </template>
            <dx-column data-field="totalPaid" caption="Paid" cell-template="totalPaidCellTemplate" />
            <template #totalPaidCellTemplate="{ data }">
              {{ getCurrencyFormat(data.value) }}
            </template>
            <dx-column data-field="balanceUsed" caption="Credit" cell-template="balanceUsedCellTemplate" />
            <template #balanceUsedCellTemplate="{ data }">
              {{ getCurrencyFormat(data.value) }}
            </template>
            <dx-column data-field="status" :customize-text="normalizeText" alignment="center" cell-template="statusCellTemplate" />
            <template #statusCellTemplate="{ data }">
              <span class="badge" :class="`badge-${statusStyle(data.value)}`">{{ data.value | capitalize }}</span>
            </template>
            <!--endregion ::DataGird columns -->
            <!--region    ::DataGird actions -->
            <dx-column data-field="id" caption="Actions" alignment="center" :width="260" cell-template="itemActions" :allow-resizing="false" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div>
                <dx-util-button
                  :visible="hasPermission"
                  :disabled="allowPaying(data)"
                  type="success" icon="bi bi-check2"
                  class="mr-half"
                  hint="Mark as Paid"
                  @click="onClickPayInvoice(data)"
                />
                <dx-util-button
                  :visible="hasPermission"
                  :disabled="allowPaying(data)"
                  type="success" icon="bi bi-credit-card-2-back-fill"
                  class="mr-half" hint="Pay invoice with credit card"
                  @click="onClickPayCreditCard(data)"
                />
                <dx-util-button
                  type="warning" icon="bi bi-reply-all-fill"
                  class="mr-half" hint="Payment Void/Refund"
                  @click="onClickTransaction(data)"
                />
                <dx-util-button
                  type="default" icon="bi bi-card-list"
                  class="mr-half" hint="Item List"
                  @click="onClickItemList(data)"
                />
                <dx-util-button
                  type="warning" icon="bi bi-printer-fill"
                  class="mr-half" hint="Print Invoice"
                  @click="onClickPrintInvoice(data)"
                />
                <dx-util-button
                  :visible="hasPermission"
                  type="aqua" icon="bi bi-list-check"
                  class="mr-half" hint="Invoice Charges"
                  @click="onClickOpenInvoiceItem(data)"
                />
              </div>
            </template>
            <!--endregion ::DataGird actions -->
            <!--region    ::DataGrid toolbar -->
            <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onOptionChangedAccountNo"
                  @value-changed="onOptionChangedAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  id="storeSelectBox"
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="onStoreChanged"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  id="statusSelectBox"
                  v-model="selectedStatus"
                  :data-source="statuses"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="onSelectedStatus"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedDateRange"
                  :data-source="dateRangesList"
                  :drop-down-options="{width: '180px'}"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="setDateRanges"
                />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="beginDate" :max="endDate" type="date" />
              </div>
              <div v-show="isCustomDateRange" class="mr-1">
                <dx-util-date-box v-model="endDate" :min="beginDate" type="date" />
              </div>
              <div>
                <dx-util-button
                  hint="Apply Filters"
                  icon="filter"
                  type="default"
                  @click="searchByFilter"
                />
              </div>
              <div v-if="hasPermission" class="text-sm-left text-center text-success text-nowrap ml-2">
                Total Due: {{ getCurrencyFormat(invoiceTotalDue) }}
              </div>
            </div>
          </template>
            <!--endregion    ::DataGrid toolbar -->
          </dx-data-grid>
        </div>
        <div class="col-md-12 px-0">
        <!--Begin:: Pay Invoice Form-->
          <dx-util-popup
            :visible="popupVisible"
            :show-close-button="false"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="true"
            :width="360"
            :height="240"
            title="Pay Invoice"
          >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form @submit="handleSubmit">
          <h5 class="text-warning">
            Invoice No: {{ selectedInvoiceNo }}
          </h5>
            <dx-util-form ref="productFormRef" :form-data="payIncoice" :col-count="1">
              <dx-util-simple-item data-field="payAmount" :editor-options="payAmountOptions" editor-type="dxNumberBox" text="Height" :label="{ text:'Enter the Amount', location: 'top'}">
                <dx-util-required-rule />
              </dx-util-simple-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
      <!--End:: Pay Invoice Form-->
        </div>
        <div class="col-md-12 px-0">
          <!--Begin:: Pay Invoice Form-->
          <dx-util-popup
            ref="payPopupRef"
            :visible="popupVisibleCC"
            :show-close-button="false"
            :drag-enabled="true"
            :resize-enabled="true"
            :close-on-outside-click="false"
            :show-title="true"
            width="80vw"
            height="60vh"
            title="Pay Invoice"
            content-template="popup-content"
            @shown="onShownPayCreditCardPopup"
            @hiding="onHidingPayCreditCardPopup"
          >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="chargeButtonOptions" />
          <template #popup-content>
            <dx-util-scroll-view width="100%" height="100%">
              <div>
                <table class="table table-bordered rounded">
                  <thead>
                    <tr>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Nickname
                      </th>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Address
                      </th>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Last Four Digits
                      </th>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Expiration Date
                      </th>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Is Default
                      </th>
                      <th scope="col" class="text-capitalize font-weight-bold">
                        Use this
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr v-for="card in selectedCompanyCreditCards" :key="card.id">
                        <td scope="row">
                          <p class="float-left">
                            {{ card.nickname }}
                          </p>
                        </td>
                        <td scope="row">
                          <p class="float-left">
                            {{ card.addressFull }}
                          </p>
                          <b-button
                            v-b-popover.hover.v-primary.bottom
                            title="Update Address"
                            variant="flat-success"
                            class="btn btn-sm btn-icon float-right"
                            @click="onClickUpdateAddress(card)"
                          >
                            <b-icon icon="pencil-fill" />
                          </b-button>
                        </td>
                        <td>
                          **** **** **** {{ card.cardLastFour }}
                        </td>
                        <td>
                          {{ card.cardExp }}
                        </td>
                        <td>
                          <h6 v-if="card.isDefault">
                            <span class="badge badge-success">Yes</span>
                          </h6>
                          <h6 v-else>
                            <span class="badge badge-warning">No</span>
                          </h6>
                        </td>
                        <td>
                          <div class="form-check">
                            <b-form-radio
                              v-model="selectedCreditCardId"
                              :value="card.id"
                              class="custom-control-success"
                            />
                          </div>
                        </td>
                      </tr>
                  </tbody>
                </table>
                <div class="row mt-1">
                  <div class="col-md-9 text-right">
                    <h5 class="text-warning mb-0">
                      Invoice No :
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <h5 class="text-warning mb-0">
                      {{ selectedInvoiceNo }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-9 text-right">
                    <h5 class="text-warning mb-0">
                      Invoice Amount :
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <h5 class="text-warning mb-0">
                      ${{ payIncoice.invoiceAmount }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-9 text-right">
                    <h5 class="text-warning mb-0">
                      Total Paid :
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <h5 class="text-warning mb-0">
                      ${{ payIncoice.totalPaid }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-md-9 text-right">
                    <h5 class="text-warning mb-0">
                      Total Due :
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <h5 class="text-warning mb-0">
                      ${{ payIncoice.totalDue }}
                    </h5>
                  </div>
                </div>
                <div class="row mt-1 d-flex">
                  <div class="col-md-9 text-right align-self-center">
                    <h5 class="text-warning mb-0 align-self-center">
                      Payment Amount :
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <dx-util-number-box
                        v-model="payIncoice.payAmount"
                        format="$ #,##0.##"
                      />
                  </div>
                </div>
                <div>
                  <update-address v-if="reCreateUpdateAddressComponent" @on-emit-close-update-address-popup="onEmitCloseUpdateAddressPopup" />
                </div>
              </div>
            </dx-util-scroll-view>
          </template>
          </dx-util-popup>
          <!--End:: Pay Invoice Form-->
          <!--Begin:: Invoice Charges-->
          <invoice-charges :component-id="invoiceChargesCompId" :invoice-id="invoiceId" />
          <!--End:: Invoice Charges-->
        </div>
      </div>
    </div>
</template>

<script>
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import { emitter } from '@/libs/bus'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import invoiceService from '@/http/requests/finance/invoiceService'
import { currencyFormatter } from '@core/utils/filter'
import staxService from '@/http/requests/external/stax'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { v4 as uuidv4 } from 'uuid'
import UpdateAddress from '~/views/apps/company/credit-card/UpdateAddress.vue'
import { invoiceDataSource } from './invoiceStore'
import InvoiceCharges from './InvoiceCharges.vue'

const today = new Date()
const last30Days = new Date()
last30Days.setDate(today.getDate() - 30)
last30Days.setHours(0, 0, 0, 0)
const plusOneDay = new Date(today)
plusOneDay.setDate(today.getDate() + 1)
plusOneDay.setHours(0, 0, 0, 0)
export default {
  components: {
    InvoiceCharges,
    UpdateAddress,
  },
  mixins: [GridBase],
  data() {
    return {
      accountNo: '',
      selectedStore: 0,
      stores: [],
      reCreateUpdateAddressComponent: false,
      invoiceChargesCompId: '',
      invoiceId: 0,
      popupVisible: false,
      popupVisibleCC: false,
      selectedDateRange: predefinedDateRangesEnum.LAST30DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      dataSource: invoiceDataSource,
      beginDate: last30Days,
      endDate: plusOneDay,
      selectedStatus: 'all',
      statuses: [
        { text: 'Status (All)', value: 'all' },
        { text: 'Open', value: 'open' },
        { text: 'Paid', value: 'paid' },
        { text: 'Disputed', value: 'disputed' },
      ],
      payIncoice: {
        id: 0,
        payAmount: 0,
        totalDue: 0,
      },
      payIncoiceDefault: {},
      selectedInvoiceNo: 0,
      payAmountOptions: {
        format: '$ #,##0.##',
      },
      selectedCompanyId: null,
      selectedCompanyCreditCards: [],
      selectedCreditCardId: '',
      invoiceTotalDue: 0,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.invoiceGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_accounting_invoice_admin')
    },
    hasAdminPermission() {
      return this.$can('read', 'resource_admin')
    },
    saveButtonOptions() {
      return {
        text: 'Charge',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    chargeButtonOptions() {
      return {
        text: 'Charge',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmitCC(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.payIncoice = { ...this.payIncoiceDefault }
          this.popupVisible = false
          this.popupVisibleCC = false
          const popup = this.$refs.payPopupRef.instance
          popup.hide()
        },
      }
    },
    currentRouteQuery() {
      return { ...this.$route.query }
    },
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return { setDateRanges, startDate, isCustomDateRange }
  },
  watch: {
    selectedDateRange() {
      this.beginDate = this.startDate || this.beginDate
    },
  },
  async created() {
    await this.getStores()
    this.accountNo = this.$route.query?.accountNo
    this.selectedStore = this.$route.query?.storeId
    this.selectedStatus = this.$route.query?.status ?? 'all'
    this.calculateTotalDue()
  },
  mounted() {
    this.payIncoiceDefault = { ...this.payIncoice }
    this.initialLoad()
  },
  methods: {
    allowPaying(e) {
      return e.row.data.status === 'paid'
    },
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.accountNo = ''
            this.selectedStore = 0
            this.selectedStatus = 'all'
            this.beginDate = last30Days
            this.endDate = plusOneDay
            this.selectedDateRange = predefinedDateRangesEnum.LAST30DAYS.value
            this.initialLoad()
          },
        },
        location: 'after',
      })
    },
    initialLoad() {
      const filters = {
        accountNo: '',
        companyId: '',
        storeId: '',
        warehouseId: 0,
        status: 'all',
        beginDate: this.beginDate,
        endDate: this.endDate,
        preview: false,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    normalizeText(cellInfo) {
      return this.$options.filters.capitalize(cellInfo.valueText)
    },
    statusStyle(status) {
      if (status.toLowerCase() === 'open') return 'warning'
      if (status.toLowerCase() === 'paid') return 'success'
      if (status.toLowerCase() === 'disputed') return 'danger'
      return 'primary'
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.value.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    searchByFilter() {
      const filters = {
        accountNo: this.accountNo,
        companyId: '',
        storeId: this.selectedStore,
        warehouseId: 0,
        status: this.selectedStatus,
        beginDate: this.beginDate,
        endDate: this.endDate,
        preview: false,
      }
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
      this.calculateTotalDue()
    },
    calculateTotalDue() {
      invoiceService.calculateTotalDue().then(result => {
        this.invoiceTotalDue = result
      })
    },
    onOptionChangedAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.getStores()
        this.searchByFilter()
      }
      this.$router.replace({ query: { ...this.currentRouteQuery, accountNo: this.accountNo } }).catch(() => {})
    },
    getStoresByParentCompanyAccountNo(accountNo) {
      commonEntityService
        .fetchStoresByParentCompanyAccountNo(accountNo)
        .then(result => {
          const data = result.data
          this.setStores(data)
        })
    },
    getStoresForCurrentCompany() {
      commonEntityService.fetchStoresForCurrentCompany().then(result => {
        const data = result.data
        this.setStores(data)
      })
    },
    getStores() {
      if (this.accountNo) {
        this.getStoresByParentCompanyAccountNo(this.accountNo)
      } else {
        this.getStoresForCurrentCompany()
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      this.stores.push({
        value: 0,
        text: 'Store (All)',
      })
      data.forEach(element => {
        this.stores.push({
          value: element.id,
          text: element.name,
          companyId: element.parentCompany,
        })
      })
      if (this.stores.length > 0) {
        const storeId = this.$route.query.storeId
        if (storeId) {
          this.selectedStore = parseInt(storeId, 10)
          return
        }
        this.selectedStore = this.stores[0].value
      }
    },
    onStoreChanged(e) {
      const storeId = e.value
      this.$router.replace({ query: { ...this.currentRouteQuery, storeId: storeId } }).catch(() => {})
    },
    onSelectedStatus(e) {
      const status = e.value
      this.$router.replace({ query: { ...this.currentRouteQuery, status: status } }).catch(() => {})
    },
    onClickItemList(e) {
      this.$router.push({ name: 'apps-invoice-detail', params: { id: e.row.data.id } })
    },
    onClickPrintInvoice(e) {
      this.$router.push({ name: 'apps-invoice-preview', params: { id: e.row.data.id } })
    },
    onClickPayInvoice(e) {
      this.selectedInvoiceNo = e.row.data.invoiceNo
      this.payIncoice.invoiceAmount = e.row.data.invoiceAmount
      this.payIncoice.totalPaid = e.row.data.totalPaid
      this.payIncoice.payAmount = e.row.data.totalDue
      this.payIncoice.totalDue = e.row.data.totalDue
      this.payIncoice.id = e.row.data.id
      this.popupVisible = true
    },
    onClickPayCreditCard(e) {
      this.selectedCompanyId = e.data.companyId
      this.selectedInvoiceNo = `${e.data.suiteNo}-${(e.data.invoiceNo.toString()).padStart(5, '0')}`
      this.payIncoice.invoiceAmount = e.row.data.invoiceAmount
      this.payIncoice.totalPaid = e.row.data.totalPaid
      this.payIncoice.payAmount = e.row.data.totalDue
      this.payIncoice.totalDue = e.row.data.totalDue.toFixed(2)
      this.payIncoice.id = e.row.data.id
      const popup = this.$refs.payPopupRef.instance
      popup.show()
    },
    async onShownPayCreditCardPopup() {
      const response = await staxService.getAllCreditCardsByCompanyId(this.selectedCompanyId)
      if (response && response.length > 0) {
        const defaultCard = response.find(c => c.isDefault)
        this.selectedCreditCardId = defaultCard.id
        this.selectedCompanyCreditCards.length = 0
        this.selectedCompanyCreditCards = [...response]
      }
    },
    onHidingPayCreditCardPopup() {
      this.selectedCompanyCreditCards.length = 0
      this.selectedCreditCardId = ''
      this.selectedCompanyId = null
    },
    handleSubmit(e) {
      e.preventDefault()
      const params = { id: this.payIncoice.id, amount: this.payIncoice.payAmount }
      invoiceService.payInvoiceByIdAndAmount(params)
        .then(() => {
          this.initialLoad()
          this.payIncoice = { ...this.payIncoiceDefault }
          this.popupVisible = false
        })
    },
    handleSubmitCC(e) {
      e.preventDefault()
      invoiceService.payInvoiceByCreditCard(this.payIncoice.id, this.payIncoice.payAmount, this.selectedCreditCardId)
        .then(() => {
          this.initialLoad()
          this.payIncoice = { ...this.payIncoiceDefault }
          this.popupVisibleCC = false
          const popup = this.$refs.payPopupRef.instance
          popup.hide()
        }).finally(() => {
          this.payIncoice = { ...this.payIncoiceDefault }
          this.popupVisibleCC = false
          const popup = this.$refs.payPopupRef.instance
          popup.hide()
        })
    },
    onClickTransaction(data) {
      const id = data.data.id
      this.$router.push({ name: 'finance-transaction-void-refund', params: { id: id } })
    },
    onClickOpenInvoiceItem(data) {
      // eslint-disable-next-line no-unused-vars
      this.invoiceId = data.data.id
      this.invoiceChargesCompId = uuidv4()
    },
    onClickUpdateAddress(item) {
      this.reCreateUpdateAddressComponent = true
      this.$nextTick(() => {
        emitter.emit('emit-update-address', item)
      })
    },
    onEmitCloseUpdateAddressPopup() {
      this.$nextTick(() => {
        this.reCreateUpdateAddressComponent = false
      })
    },
  },
}
</script>

<style>

</style>
