/* eslint-disable prefer-rest-params */
import find from 'lodash/find'
/**
 * Enum for Charge Type Enums.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const chargeTypeEnum = Object.freeze({
  PREP_MATERIAL: { key: 'prep_material', value: 0, text: 'Item Prep Material' },
  ITEM_PREP_CHARGE: { key: 'item_prep_charge', value: 1, text: 'Item Prep Charge' },
  BUNDLE_PREP_CHARGE: { key: 'bundle_prep_charge', value: 2, text: 'Bundle Prep Charge' },
  SERVICE: { key: 'service', value: 3, text: 'Service' },
  STORAGE: { key: 'storage', value: 4, text: 'Storage' },
  SHIPPING: { key: 'shipping', value: 5, text: 'Shipping' },
  RETURN_CHARGE: { key: 'return_charge', value: 6, text: 'Return Charge' },
  EXTRA_CHARGE: { key: 'extra_charge', value: 7, text: 'Extra Charge' },
  OTHER: { key: 'other', value: 10, text: 'Other' },
  FBM_PICK_PACK_CHARGE: { key: 'fbm_pick_pack_charge', value: 11, text: 'FBM Pick/Pack Charge' },
  PROBLEM: { key: 'problem', value: 12, text: 'Problem' },
})

function getChargeTypeEnum(predicate, predicateKey = 'key') {
  const result = find(chargeTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListChargeTypeEnum() {
  const result = Object.keys(chargeTypeEnum).map(key => chargeTypeEnum[key])
  return result
}

function getSelectedListChargeTypeEnum() {
  const args = Array.from(arguments)
  const result = []
  args.forEach(item => {
    result.push(item)
  })
  return result
}

export default chargeTypeEnum

export {
  getChargeTypeEnum,
  getListChargeTypeEnum,
  getSelectedListChargeTypeEnum,
}
