<template>
  <dx-util-popup
    ref="invoiceChargesPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="true"
    :title="invoiceChargesPopupTitle"
    content-template="popup-content"
    @shown="onShownInvoiceChargesPopup"
    @hiding="onHidingInvoiceChargesPopup"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
    <template #popup-content>
      <dx-util-scroll-view width="100%" height="100%">
        <div class="row">
          <div class="col-12 pr-3">
            <div v-if="showAddNew || showEdit || showApplyDiscount" class="row">
              <div class="form-group col-2">
                <dx-util-select-box
                  v-model="invoiceItemForm.changeType"
                  :data-source="chargeTypes"
                  :drop-down-options="{width: '180px'}"
                  :read-only="showApplyDiscount"
                  display-expr="text"
                  value-expr="key"
                  label="Charge Type"
                  label-mode="floating"
                />
              </div>
              <div class="form-group col-2">
                <dx-util-autocomplete
                  v-model="invoiceItemForm.itemGroupName"
                  :data-source="itemGroupNames"
                  :read-only="showApplyDiscount"
                  :min-search-length="3"
                  :max-item-count="30"
                  :show-clear-button="true"
                  search-expr="title"
                  display-expr="title"
                  value-expr="title"
                  label="Prep Material Name"
                  label-mode="floating"
                  @selection-changed="onSelectionChangedItemGroupNames($event)"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Prep Material is required" />
                  </dx-util-validator>
                </dx-util-autocomplete>
              </div>
              <div class="form-group col-1">
                <dx-util-number-box
                  v-model="invoiceItemForm.chargeRate"
                  :format="{type: 'currency', precision: 2 }"
                  :hover-state-enabled="false"
                  label="Charge Rate"
                  label-mode="floating"
                  @value-changed="calculateTotal"
                />
              </div>
              <div class="form-group col-1">
                <dx-util-number-box
                  v-model="invoiceItemForm.quantity"
                  :hover-state-enabled="false"
                  :read-only="showApplyDiscount"
                  label="Quantity"
                  label-mode="floating"
                  @value-changed="calculateTotal"
                />
              </div>
              <div class="form-group col-1">
                <dx-util-number-box
                  v-model="invoiceItemForm.totalCost"
                  :format="{type: 'currency', precision: 2 }"
                  :read-only="true"
                  :hover-state-enabled="false"
                  label="Total Cost"
                  label-mode="floating"
                />
              </div>
              <div class="form-group" :class="showAddNew || showApplyDiscount ? 'col-3' : 'col-4'">
                <dx-util-text-box
                  v-model="invoiceItemForm.notes"
                  :read-only="showApplyDiscount"
                  label="Notes"
                  label-mode="floating"
                />
              </div>
              <div v-if="showAddNew || showApplyDiscount" class="form-group col-1">
                <dx-util-number-box
                  v-model="invoiceItemForm.discount"
                  format="#0.##%"
                  :min="0"
                  :max="100"
                  :hover-state-enabled="false"
                  label="Discount"
                  label-mode="floating"
                  @value-changed="calculateTotal"
                />
              </div>
              <div class="form-group d-flex flex-row justify-content-between col-1 pt-half">
                <dx-util-button
                  :visible="showEdit"
                  hint="Update Charges"
                  text="Update"
                  class="mr-half align-self-center w-100"
                  type="success"
                  height="42"
                  @click="onHandeSubmitEdit"
                />
                <dx-util-button
                  :visible="showApplyDiscount"
                  text="Apply"
                  class="mr-half align-self-center w-100"
                  type="success"
                  height="42"
                  @click="onHandeSubmitApplyDiscount"
                />
                <dx-util-button
                  :visible="showAddNew"
                  text="Save"
                  class="mr-half align-self-center w-100"
                  type="success"
                  height="42"
                  @click="onAddNewItemSave"
                />
                <dx-util-button
                  text="Cancel"
                  type="normal"
                  class="btn-warning align-self-center w-100"
                  height="42"
                  @click="onResetForm"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <dx-data-grid
              id="invoiceChargesGrid"
              ref="invoiceChargesGridRef"
              key-expr="id"
              :data-source="dataSourceArr"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
            >
              <dx-column data-field="costGroupName" caption="Cost Group" />
              <dx-column data-field="itemGroupName" caption="Item" />
              <dx-column data-field="quantity" caption="Quantity" />
              <dx-column data-field="chargeRate" caption="Rate" :format="{ type: 'currency', precision: 2 }" />
              <dx-column data-field="discount" caption="Discount" :format="{ type: 'percent'}" />
              <dx-column data-field="totalCost" caption="Total Cost" :format="{ type: 'currency', precision: 2 }" />
              <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
              <template #itemActions="{data}">
                <div>
                  <dx-util-button
                    type="default"
                    icon="percent"
                    class="mr-half"
                    hint="Apply Discount"
                    @click="onSelectItemAddDiscount(data)"
                  />
                  <dx-util-button
                    type="warning"
                    icon="bi bi-pencil-square"
                    class="mr-half"
                    hint="Edit Charge"
                    @click="onSelectItemEdit(data)"
                  />
                  <dx-util-button
                    type="danger" icon="bi bi-trash-fill"
                    class="mr-half" hint="Delete"
                    @click="onClickDelete(data)"
                  />
                </div>
              </template>
            </dx-data-grid>
          </div>
          <div class="ml-lg-auto align-self-right px-2 mt-2">
            <dx-util-button
              icon="plus"
              text="Add New Charge"
              class="mr-half"
              type="success"
              width="100%"
              @click="onAddNewCharge"
            />
          </div>
        </div>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
</template>

<script>
/* eslint-disable no-unused-vars */
import invoiceItemService from '@/http/requests/finance/invoiceItemService'
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'
import chargeTypeEnum, { getChargeTypeEnum, getListChargeTypeEnum } from '@/enums/charge-type.enum'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      invoiceChargesPopupTitle: '',
      dataSourceArr: [],
      invoiceItemForm: {
        id: null,
        invoiceId: 0,
        costGroup: 0,
        costGroupName: '',
        itemGroupName: '',
        changeType: 'prep_material',
        quantity: 1,
        discount: 0,
        chargeRate: 0,
        totalCost: 0,
        notes: '',
        preview: false,
      },
      showAddNew: false,
      showEdit: false,
      showApplyDiscount: false,
      itemGroupNames: [],
      chargeTypes: getListChargeTypeEnum(),
    }
  },
  computed: {
    popupIns() {
      const popup = this.$refs.invoiceChargesPopupRef.instance
      return popup
    },
    cancelButtonOptions() {
      return {
        text: 'Close',
        type: 'danger',
        onClick: () => {
          this.onCloseInvoiceChargesPopup()
        },
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.dataSourceArr = []
          this.createInvoiceChargesPopup()
        }
      },
    },
  },
  methods: {
    setDefaultInvoiceItemFormData() {
      this.invoiceItemForm = {
        id: null,
        invoiceId: 0,
        costGroup: 0,
        costGroupName: '',
        itemGroupName: '',
        changeType: 'prep_material',
        quantity: 1,
        discount: 0,
        chargeRate: 0,
        totalCost: 0,
        notes: '',
        preview: false,
      }
    },
    async setStoreData() {
      this.setDefaultInvoiceItemFormData()
      const result = await invoiceItemService.fetchAllByInvoiceId(this.invoiceId)
      if (result) {
        this.dataSourceArr = []
        result.forEach(async (item, index) => {
          const newItem = item
          newItem.discount = item.discount / 100
          this.dataSourceArr.push(newItem)
        })
        this.getItemGroupNames()
      }
    },
    createInvoiceChargesPopup() {
      this.setStoreData()
      this.popupIns.show()
    },
    onShownInvoiceChargesPopup() {
      this.invoiceChargesPopupTitle = 'Invoice Charges'
    },
    onCloseInvoiceChargesPopup() {
      this.popupIns.hide()
    },
    onHidingInvoiceChargesPopup() {
      this.onResetForm()
    },
    getItemGroupNames() {
      const getItem = 'page=0&size=500'
      prepMaterialInventoryService.fetchAll(getItem).then(response => {
        const data = response.data.content
        this.itemGroupNames.length = 0
        this.itemGroupNames = [...data]
      })
    },
    onSelectionChangedItemGroupNames(e) {
      const {
        costGroup, costGroupId, salePrice, title,
      } = e.selectedItem
      this.invoiceItemForm.costGroup = costGroupId
      this.invoiceItemForm.costGroupName = costGroup
      this.invoiceItemForm.chargeRate = salePrice
      this.invoiceItemForm.itemGroupName = title
    },
    calculateTotal() {
      if (this.invoiceItemForm.discount === 0) {
        this.invoiceItemForm.totalCost = this.invoiceItemForm.chargeRate * this.invoiceItemForm.quantity
      } else {
        let totalCost = this.invoiceItemForm.chargeRate * this.invoiceItemForm.quantity
        totalCost -= (totalCost * this.invoiceItemForm.discount)
        this.invoiceItemForm.totalCost = totalCost
      }
    },
    async onAddNewItemSave() {
      const { discount } = this.invoiceItemForm
      const discountPercent = parseInt(discount * 100, 0)
      this.invoiceItemForm.invoiceId = this.invoiceId
      this.invoiceItemForm.discount = discountPercent
      if (this.invoiceItemForm.changeType !== chargeTypeEnum.PREP_MATERIAL.key) {
        const chargeType = getChargeTypeEnum(this.invoiceItemForm.changeType)
        this.invoiceItemForm.costGroupName = chargeType.text
      }
      await invoiceItemService.create(this.invoiceItemForm)
      await this.setStoreData()
      this.setDefaultInvoiceItemFormData()
      this.onResetForm()
    },
    async onHandeSubmitEdit() {
      await invoiceItemService.update(this.invoiceItemForm)
      await this.setStoreData()
      this.setDefaultInvoiceItemFormData()
      this.onResetForm()
    },
    async onHandeSubmitApplyDiscount() {
      const { id, discount } = this.invoiceItemForm
      const discountPercent = parseInt(discount * 100, 0)
      await invoiceItemService.appllyDiscount(id, discountPercent)
      await this.setStoreData()
      this.setDefaultInvoiceItemFormData()
      this.onResetForm()
    },
    async onClickDelete(e) {
      const id = e.row.key
      await invoiceItemService.deleteById(id)
      await this.setStoreData()
    },
    onAddNewCharge(e) {
      this.onResetForm()
      this.showAddNew = true
    },
    onSelectItemAddDiscount(e) {
      this.onResetForm()
      const { data } = e.row
      this.invoiceItemForm = { ...data }
      this.showApplyDiscount = true
    },
    onSelectItemEdit(e) {
      this.onResetForm()
      const { data } = e.row
      this.invoiceItemForm = { ...data }
      this.showEdit = true
    },
    onResetForm() {
      this.showAddNew = false
      this.showEdit = false
      this.showApplyDiscount = false
      this.setDefaultInvoiceItemFormData()
    },
  },
}
</script>

<style></style>
