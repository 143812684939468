import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import invoiceService from '@/http/requests/finance/invoiceService'
import Pager from '@core/dev-extreme/utils/pager'

function sortFieldMap(sort) {
  const sortBy = []
  sort.forEach(c => {
    let field = c.selector
    if (c.selector === 'balanceUsed') field = 'customer_balance_used'
    sortBy.push({ selector: field, desc: c.desc })
  })
  return sortBy
}

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? sortFieldMap(loadOptions.sort) : [{ selector: 'id', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await invoiceService.fetchAllByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const invoiceDataSource = new DataSource({
  store: store,
})

export {
  invoiceDataSource,
}
